<template>
  <div>
    <GeneralDashboard @notify-parent="searchComplaints" />

    <div
      v-if="!loading"
      class="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 3xl:grid-cols-8 md:gap-x-5 gap-1 justify-around"
    >
      <ComplaintCardMini
        v-for="complaint in complaints"
        :key="complaint.title"
        :title="complaint.title"
        :numComplaints="complaint.numComplaints"
        :percent="complaint.percent"
        :danger="complaint.danger"
        :fall="complaint.fall"
      />
    </div>

    <div v-if="!loading" class="general-report-section">
      <!-- Total de quejas por entidad. -->
      <ComplaintsChartBars
        title="Total de quejas de fraude por entidad"
        subtitle="Se visualizaran las primeras 10 entidades con mayor número de quejas"
        horizontal
        :datax="categoriesEntity"
        :datay="seriesEntity"
        class="complaints-by-entity"
      />

      <!-- Total de quejas por producto. -->
      <ComplaintsChartBars
        title="Total de quejas de fraude por producto"
        subtitle="Se visualizaran los primeros 5 productos con mayor número de quejas"
        :datay="seriesProduct"
        :datax="categoriesProduct"
        class="complaints-by-product"
      />

      <!-- Modalida -->
      <ComplaintsChartBars
        title="Total de quejas de fraude por modalidad"
        subtitle="Se visualizaran las primeras 10 modalidades con mayor número de quejas"
        :datay="seriesModalidad"
        :datax="categoriesModalidad"
        class="complaints-by-modalidad"
      />

      <!-- Tendencia de las Quejas de fraude por Fecha. -->
      <ComplaintTrendsByDate
        title="Tendencia de las quejas de fraude por fecha"
        :labelsx="labelsComplaintsDate"
        :line="lineComplaint"
        :bars="barsEntity"
        class="complaints-by-date"
      />

      <ColumnCharts
        title="Monto reconocido vs reclamado por fecha"
        :seriesData="seriesAmount"
        :categories="categoriesAmount"
        class="amount-recognized-claimed"
      />
    </div>
         
    <div v-if="!loading">
      <RecurrentPetitionersTable
        title="Listado de peticionarios recurrentes "
        :tdata="recurrentPetitioners"
        :page="recurrentPetitionersPage"
        :count="recurrentPetitionersCount"
        @onPageChange="updateRecurrentPetitionersPage"
        style="margin: 1rem 0rem"
        :downloadRecurrentPetitioners="recurrentPetitionersDownload"
        :downloadAllRecurrentPetitioners="allRecurrentPetitioners"
      />
    </div>

    <div v-if="loading" class="flex justify-center items-center mt-32">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
  </div>
</template>
  
  <script>
import moment from "moment";
import capitalize from "@/utils/checkForms.js";
import Extension from "@/components/Dashboard/Management/Extension";
import GeneralDashboard from "@/components/Dashboard/Management/GeneralDashboard";
import ColumnCharts from "@/components/Dashboard/Management/General/ColumnCharts.vue";
import ComplaintCardMini from "@/components/Dashboard/Management/General/ComplaintCardMini";
import ComplaintsChartBars from "@/components/Dashboard/Management/General/ComplaintsChartBars.vue";
import ComplaintTrendsByDate from "@/components/Dashboard/Management/General/ComplaintTrendsByDate.vue";
import RecurrentPetitionersTable from "@/components/Dashboard/Management/General/RecurrentPetitionersTable.vue";
import ComplaintResolutionProductivity from "@/components/Dashboard/Management/General/ComplaintResolutionProductivity.vue";

const arrVolumetry = [
  {
    name: "Total de quejas",
    target: "total_codigo_queja",
    percent: "porcentaje_codigo_queja",
  },
  {
    name: "Total quejas abiertas",
    target: "total_quejas_Abierta",
    percent: "porcentaje_quejas_Abierta",
  },
  {
    name: "Total Entidades",
    target: "total_nombre_entidad",
    percent: "porcentaje_nombre_entidad",
  },
  {
    name: "Total Usuarios",
    target: "total_numero_id_CF",
    percent: "porcentaje_numero_id_CF",
  },
  {
    name: "Tiempo total de respuesta",
    target: "sum_secconds",
    percent: "porcentaje_sum_secconds",
  },
  {
    name: "Tiempo promedio de respuesta",
    target: "avg_secconds",
    percent: "porcentaje_avg_secconds",
  },
  {
    name: "Total fraude interno",
    target: "total_fraude_Interno",
    percent: "porcentaje_fraude_Interno",
  },
  {
    name: "Total fraude externo",
    target: "total_fraude_Externo",
    percent: "porcentaje_fraude_Externo",
  },
];

export default {
  components: {
    Extension,
    GeneralDashboard,
    ComplaintCardMini,
    ComplaintTrendsByDate,
    ComplaintResolutionProductivity,
    RecurrentPetitionersTable,
    ComplaintsChartBars,
    ColumnCharts,
  },
  data() {
    return {
      complaintsInfo: [],

      //Complaints
      complaints: [],

      // recurrent petitioners list
      recurrentPetitioners: [],
      recurrentPetitionersDownload: [],
      allRecurrentPetitioners: [],

      //complaits by product
      seriesProduct: [],
      categoriesProduct: [],

      //complaits by modalidad
      seriesModalidad: [],
      categoriesModalidad: [],

      //complaints by entity
      categoriesEntity: [],
      seriesEntity: [],

      // complaint trends by date
      labelsComplaintsDate: [],
      lineComplaint: {
        name: "Total Quejas",
        type: "line",
        data: [],
      },
      barsEntity: {
        name: "Total Entidades",
        type: "column",
        data: [],
      },

      // Montos
      seriesAmount: [],
      categoriesAmount: [],

      extensionsPage: 1,
      extencionsCount: 0,
      extensionsDownload: [],
      allExtensions: [],
      recurrentPetitionersPage: 1,
      recurrentPetitionersCount: 0,
      pageSize: 10,

      actualQuery: {},
      loading: false,
    };
  },
  watch: {
    extensionsPage: function (newVal, oldVal) {
      if (newVal == this.$route.query.extensionsPage) {
        return;
      } else {
        this.$router.replace({
          query: { extensionsPage: this.extensionsPage },
        });
        this.queryExtensions();
      }
    },
    recurrentPetitionersPage: function (newVal, oldVal) {
      if (newVal == this.$route.query.recurrentPetitionersPage) {
        return;
      } else {
        this.$router.replace({
          query: { recurrentPetitionersPage: this.recurrentPetitionersPage },
        });
        this.queryRecurrentPetitioners();
      }
    },
  },
  created() {
    if (this.$route.query.page) {
      this.extensionsPage = parseInt(this.$route.query.page);
    } else {
      this.extensionsPage = 1;
    }
    // Se carga por defecto los valores para una semana
    this.searchComplaints({
      filter_model: {
        fecha_creacion: {
          filter_type: "date",
          operator: "AND",
          condition1: {
            date_from: moment()
              .set(new Date())
              .subtract(7, "d")
              .format("YYYY-MM-DD"),
            date_to: null,
            filter_type: "date",
            type: "greaterThanOrEqual",
          },
          condition2: {
            date_from: moment().set(new Date()).format("YYYY-MM-DD"),
            date_to: null,
            filter_type: "date",
            type: "lessThanOrEqual",
          },
        },
      },
    });
  },
  methods: {
    async searchComplaints(ev) {
      this.showToast("info", "Consultando gráficas...");
      this.loading = true;
      this.actualQuery = ev;
      const promises = [];

      // Construcción de todas las peticiones para la vista de reporte general

      // 1. Petición de volumetría
      const bodyVolumetry = {
        filter_model: this.actualQuery?.filter_model,
        chart_type: "fraud",
      };
      promises.push(this.$api.getVolumetry(bodyVolumetry));

      // 2. Total quejas por entidad
      const bodyCompany = {
        filter_model: this.actualQuery?.filter_model,
        group_model: ["nombre_entidad"],
        chart_type: "fraud",
        limit: 10,
        offset: 0,
      };
      promises.push(this.$api.applyFilters(bodyCompany));

      // 3. Total de quejas por producto
      const bodyProduct = {
        filter_model: this.actualQuery?.filter_model,
        group_model: ["producto_nombre"],
        chart_type: "fraud",
        limit: 5,
        offset: 0,
      };
      promises.push(this.$api.applyFilters(bodyProduct));

      // 4. Tendencias de las quejas por fecha
      const bodyDate = {
        fields_count_distinct: ["nombre_entidad"],
        filter_model: {
          ...this.actualQuery?.filter_model,
          fecha_creacion: this.actualQuery?.filter_model?.fecha_creacion,
        },
        chart_type: "fraud",
        group_model: ["DATE(q.fecha_creacion)"],
        order_model: ["-DATE(q.fecha_creacion)"],
      };
      promises.push(this.$api.applyFilters(bodyDate));

      // 5. Modalidad
      const bodyModalidad = {
        filter_model: this.actualQuery?.filter_model,
        group_model: ["modalidad_fraude"],
        chart_type: "fraud",
        limit: 10,
        offset: 0,
      };
      promises.push(this.$api.applyFilters(bodyModalidad));

      // 6.
      const bodyAmountRecognizedClaimed = {
        filter_model: {
          ...this.actualQuery?.filter_model,
          fecha_creacion: this.actualQuery?.filter_model?.fecha_creacion,
        },
        chart_type: "fraud",
        group_type: "avg",
        group_model: [
          "DATE(q.fecha_creacion)",
          "q.monto_reclamado",
          "q.monto_reconocido",
        ],
        order_model: ["-DATE(q.fecha_creacion)"],
      };
      promises.push(this.$api.applyFilters(bodyAmountRecognizedClaimed));

      // Ejecutamos todas las peticiones al backend
      Promise.allSettled(promises)
        .then((response) => {
          // console.log(response);

          // 1. Volumetría
          const volumetry = response[0]?.value?.data;
          // console.log(volumetry);

          this.complaints = [];
          let factor = 1;

          arrVolumetry.forEach((item) => {
            factor = item.target.includes("secconds") ? 3600 : 1;

            if (volumetry[item.percent] === null) {
              this.complaints.push({
                title: item.name,
                percent: 0,
                numComplaints: 0,
                danger: false,
                fall: false,
              });
            } else {
              this.complaints.push({
                title: item.name,
                percent: +(
                  volumetry[item.percent] < 0
                    ? -1 * volumetry[item.percent]
                    : volumetry[item.percent]
                ).toFixed(2),
                numComplaints: volumetry[item.target] / factor,
                danger: volumetry[item.percent] < 0,
                fall: volumetry[item.percent] < 0,
              });
            }
          });

          // 2. Total de quejas por entidades
          const companies = response[1]?.value?.data?.results;
          const auxCompany = [];
          this.categoriesEntity = [];
          this.seriesEntity = [];
          companies.forEach((item) => {
            this.categoriesEntity.push(item.nombre_entidad + "");
            auxCompany.push(item.total);
          });
          this.seriesEntity.push({ data: auxCompany });

          // 3. Total de quejas por producto
          const products = response[2]?.value?.data?.results;
          const auxProduct = [];
          this.categoriesProduct = [];
          this.seriesProduct = [];
          products.forEach((item) => {
            this.categoriesProduct.push(item.producto_nombre);
            auxProduct.push(item.total);
          });
          this.seriesProduct.push({ data: auxProduct });

          // 4. Tendencia de quejas por fecha
          let complaints_date = response[3]?.value?.data?.results;
          complaints_date = complaints_date.sort(function (a, b) {
            return new Date(a.f0_) - new Date(b.f0_);
          });
          this.labelsComplaintsDate = [];
          this.lineComplaint.data = [];
          this.barsEntity.data = [];
          complaints_date.forEach((item) => {
            this.labelsComplaintsDate.push(item.f0_);
            this.lineComplaint.data.push(item.total);
            this.barsEntity.data.push(item.total_nombre_entidad);
          });

          // 5.  Total quejas por modalidad
          const modalidad = response[4]?.value?.data?.results;
          const auxModalidad = [];
          this.categoriesModalidad = [];
          this.seriesModalidad = [];
          modalidad.forEach((item) => {
            if (item.modalidad_fraude) {
              this.categoriesModalidad.push(item.modalidad_fraude);
              auxModalidad.push(item.total);
            }
          });
          this.seriesModalidad.push({ data: auxModalidad });

          // bodyAmountRecognizedClaimed - Monto reconocido vs reclamado por fecha
          let amountRecognizedClaimed = response[5]?.value?.data?.results;

          amountRecognizedClaimed = amountRecognizedClaimed.sort(function (
            a,
            b
          ) {
            return new Date(a.f0_) - new Date(b.f0_);
          });
          const categoryAmount = [];
          const reclamadof1 = [];
          const reconocidof2 = [];
          amountRecognizedClaimed.map((element) => {
            console.log(element);
            categoryAmount.push(element.f0_);
            reclamadof1.push(element.f1_);
            reconocidof2.push(element.f2_);
          });

          this.categoriesAmount = categoryAmount;
          this.seriesAmount = [
            {
              name: "Monto reclamado",
              data: reclamadof1,
            },
            {
              name: "Monto reconocido",
              data: reconocidof2,
            },
          ];
          this.loading = false;
        })
        .catch((error) => console.error(error));

      // Listado de peticionarios recurrentes
      this.queryRecurrentPetitioners(this.actualQuery);

      //Quejas
      this.queryExtensions();
    },
    queryRecurrentPetitioners(ev = this.actualQuery) {
      // Peticionarios recurrentes
      const bodyRecurring = {
        filter_model: ev?.filter_model,
        limit: this.pageSize,
        offset: this.recurrentPetitionersPage - 1,
        group_model: ["tipo_identificacion_CF", "numero_id_CF", "nombres"],
        type: "recurring",
        chart_type: "fraud",
      };

      this.$api.applyFilters(bodyRecurring).then((response) => {
        const recurrency = response?.data;
        const maxComplaint = recurrency?.results[0]?.total;
        this.recurrentPetitioners = recurrency.results.map((item, index) => {
          return {
            index: index + 1,
            typeId: item.tipo_identificacion_CF,
            identification: item.numero_id_CF,
            username: item.nombres,
            complaints: item.total,
            details: item.details,
            complaintsSolved: +((item.total * 100) / maxComplaint).toFixed(2),
          };
        });
        this.recurrentPetitionersDownload = recurrency.results
          .map((item) => {
            return (item.details || []).map((detail) => {
              return {
                "Tipo de identificación": item.tipo_identificacion_CF,
                Número: item.numero_id_CF,
                Nombre: capitalize(item.nombres),
                "Total de quejas": item.total,
                Radicado: detail._field_1,
                Producto: detail._field_2,
                Motivo: detail._field_3,
              };
            });
          })
          .flat();
        this.recurrentPetitionersCount = response.data.count;
      });
      this.downloadAllRecurrentPetitioners(ev);
    },
    queryExtensions(ev = this.actualQuery) {
      // Tabla de quejas
      const bodyComplaint = {
        filter_model: ev?.filter_model,
        limit: this.pageSize,
        offset: this.extensionsPage - 1,
      };
      this.$api.searchComplaints(bodyComplaint).then((response) => {
        this.complaintsInfo = response.data.results.map((item) => {
          return {
            ...item,
            prorroga_queja: item.prorroga_queja ? item.prorroga_queja : 0,
            total_segundos: item.total_segundos ? item.total_segundos : 0,
          };
        });
        this.extensionsDownload = response.data.results.map((item) => {
          return {
            Radicado: item.codigo_queja,
            "Fecha de creación": item.fecha_creacion,
            "Fecha de cierre": item.fecha_cierre,
            Entidad: capitalize(item.nombre_entidad),
            "Queja Extress": item.queja_expres,
            Prórroga: item.prorroga_queja ? item.prorroga_queja : 0,
            "Tiempo total":
              (item.total_segundos / 86400).toFixed(0) > 0
                ? (item.total_segundos / 86400).toFixed(0) + " días"
                : (item.total_segundos / 3600).toFixed(0) + " horas",
          };
        });
        this.extencionsCount = response?.data.count;
      });
      this.downloadAllExtensions(ev);
    },
    downloadAllExtensions(ev = this.actualQuery) {
      const bodyComplaint = {
        filter_model: ev?.filter_model,
        offset: this.extensionsPage - 1,
      };
      this.$api
        .searchComplaints(bodyComplaint)
        .then((response) => {
          this.allExtensions = response?.data?.results?.map((item) => {
            return {
              Radicado: item.codigo_queja,
              "Fecha de creación": item.fecha_creacion,
              "Fecha de cierre": item.fecha_cierre,
              Entidad: capitalize(item.nombre_entidad),
              "Queja Extress": item.queja_expres,
              Prórroga: item.prorroga_queja ? item.prorroga_queja : 0,
              "Tiempo total":
                (item.total_segundos / 86400).toFixed(0) > 0
                  ? (item.total_segundos / 86400).toFixed(0) + " días"
                  : (item.total_segundos / 3600).toFixed(0) + " horas",
            };
          });
        })
        .catch((err) => console.error(err));
    },
    downloadAllRecurrentPetitioners(ev = this.actualQuery) {
      const bodyRecurring = {
        filter_model: ev?.filter_model,
        offset: this.recurrentPetitionersPage - 1,
        group_model: ["tipo_identificacion_CF", "numero_id_CF", "nombres"],
        chart_type: "fraud",
        type: "recurring",
      };
      this.$api.applyFilters(bodyRecurring).then((response) => {
        const recurrency = response?.data?.results;
        this.allRecurrentPetitioners = recurrency
          .map((item) => {
            return (item.details || []).map((detail) => {
              return {
                "Tipo de identificación": item.tipo_identificacion_CF,
                Número: item.numero_id_CF,
                Nombre: capitalize(item.nombres),
                "Total de quejas": item.total,
                Radicado: detail._field_1,
                Producto: detail._field_2,
                Motivo: detail._field_3,
              };
            });
          })
          .flat();
      });
    },

    //#endregion

    // #region Actualizar datos de las tabla
    updateRecurrentPetitionersPage(newPage) {
      this.recurrentPetitionersPage = newPage;
    },
    updateExtensionsPage(newPage) {
      this.extensionsPage = newPage;
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
    // #endregion
  },
};
</script>
  
  <style scoped>
.general-report-section {
  box-sizing: border-box;
  height: auto;
  display: grid;
  grid-template-columns: repeat(2, minmax(50%, 1fr));
  grid-template-rows: max-content 1fr 45em max-content;
  /* grid-template-rows: repeat(4, minmax(400, 1fr)); */

  grid-gap: 1em;

  grid-template-areas:
    "petitioners petitioners"
    "complaints-by-entity complaints-by-product"
    "complaints-by-modalidad complaints-by-modalidad"
    "complaints-by-date complaints-by-date"
    "amount-recognized-claimed amount-recognized-claimed";
}

.unconformities {
  grid-area: unconformities;
}
.petitioners {
  grid-area: petitioners;
}
.complaints-by-product {
  grid-area: complaints-by-product;
}
.complaints-by-modalidad {
  grid-area: complaints-by-modalidad;   
}
.complaints-by-entity {
  grid-area: complaints-by-entity;
}
.complaints-by-date {
  grid-area: complaints-by-date;
}
.amount-recognized-claimed {
  grid-area: amount-recognized-claimed;
}
@media only screen and (max-width: 1024px) {
  .general-report-section {
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content 40em max-content 40em 40em 500px;
    grid-template-areas:
      "petitioners"
      "complaints-by-entity "
      "complaints-by-product"
      "complaints-by-modalidad"
      "complaints-by-date"
      "amount-recognized-claimed";
    grid-gap: 1em;
  }
}
</style>
  