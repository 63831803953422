<template>
  <section
    class="bg-white sm:text-left shadow-xl rounded-xl sm:p-14 sm:px-14 sm:w-full sm:p-10 py-10 px-4" style="height: 500px;"
  >
    <h3 class="sm:text-xl font-bold text-base">{{ title }}</h3>
    <apexchart
      type="bar"
      height="100%"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </section>
</template>
  <script>
export default {
  // Datos de para el eje de las ordenadas y el eje de las axisas
  props: {
    title: {
      type: String,
      required: true,
    },
    categories: {
      type: [],
      required: true,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    seriesData: {
      type: [],
      required: true,
    },
    distributed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      series: this.seriesData,
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: this.categories,
        },
        yaxis: {
          title: {
            text: "(montos)",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " montos";
            },
          },
        },
        // responsive: [
        //   {
        //     breakpoint: 1024,
        //     options: {
        //       chart: {
        //         // height: "90%",
        //         // width: "100%",
        //         zoom: {
        //           enabled: false,
        //         },
        //       },
        //       plotOptions: {
        //         bar: {
        //           borderRadius: 3,
        //         },
        //       },
        //       legend: {
        //         position: "bottom",
        //         fontSize: "12em",
        //       },
        //       yaxis: [
        //         {
        //           title: {
        //             text: "",
        //           },
        //         },
        //         {
        //           opposite: true,
        //           title: {
        //             text: "",
        //           },
        //         },
        //       ],
        //     },
        //   },
        //   {
        //     breakpoint: 600,
        //     options: {
        //       plotOptions: {
        //         bar: {
        //           borderRadius: 1,
        //         },
        //       },
        //     },
        //   },
        // ],
      },
    };
  },
  created() {
    // this.chartOptions.xaxis.categories = this.datax;
    // this.series = this.datay;
    // this.chartOptions.plotOptions.bar.distributed = this.distributed;
    // this.chartOptions.plotOptions.bar.horizontal = this.horizontal;
    // this.chartOptions.dataLabels.offsetX = this.horizontal ? -20 : 0;
    // this.chartOptions.dataLabels.offsetY = !this.horizontal ? 20 : 0;
  },
};
</script>
  <style scoped>
.card {
  height: 30em;
}
</style>